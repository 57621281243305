<template>
  <v-dialog v-model="myDetailDialog" max-width="1000" persistent>
    <v-card loader-height="2">
      <v-card-title>View Details</v-card-title>

      <v-card-text class="view_data">
        <!-- start -->
        <v-row>
          <v-col cols="12" lg="3">
            <v-card outlined>
              <v-card-text>
                <div class="my_text_list">
                  <ul>
                    <li>
                      <div class="title">price</div>
                      <div class="text">
                        {{ currency(view.price) }}
                      </div>
                    </li>
                    <li>
                      <div class="title">appointment_date</div>
                      <div class="text">
                        {{ view.appointment_date | myDating }}
                      </div>
                    </li>
                    <li>
                      <div class="title">accepted</div>
                      <div class="text">
                        {{ view.accepted ? "Yes" : "No" }}
                      </div>
                    </li>
                    <li>
                      <div class="title">pay</div>
                      <div class="text">
                        {{ view.pay ? "Yes" : "No" }}
                      </div>
                    </li>
                    <li>
                      <div class="title">done</div>
                      <div class="text">
                        {{ view.done ? "Yes" : "No" }}
                      </div>
                    </li>
                  </ul>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" lg="9">
            <v-card outlined>
              <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="view.response"
                    :items-per-page="10"
                >
                  <template v-slot:item.pricing="{ item }">
                    {{ currency(item.pricing) }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- end -->
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "../../../mixins/utils.js"

export default {
  name: "accepts",
  mixins: [Utils],
  props: {
    myDetailDialog: {type: Boolean},
    myId: {type: Number},
    request: {type: Number},
  },
  data: () => ({
    view: {},
    headers: [
      {text: "service_name", value: "service_name"},
      {text: "pricing", value: "pricing"},
      {text: "detail", value: "comment"},
      {text: "instructions", value: "instructions"},
    ],
  }),
  created() {
    this.detail();
  },
  methods: {
    detail() {
      let id = parseInt(this.request);
      Restful.medics.treatment.transact.providers.labs
          .detail(id)
          .then((response) => {
            console.log(response);
            if (response.status === 200) {
              this.view = response.data;
            }
          })
          .catch((error) => {
            console.log(error.response);
            self.snack.color = "error";
            self.snack.text = error.response.data;
          });
    },
    closeDialog() {
      let payload = {id: this.myId, state: false};
      this.$emit("closeMyDetail", payload);
    },
  }
}
</script>