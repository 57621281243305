<template>
  <div class="page">
    <div class="heading">
      <v-toolbar :flat="flatApp" dense>
        <v-btn icon @click="menuLink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs
            :items="crumbs"
            class="capitalize"
            style="padding: 14px 20px"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn color="primary" icon @click="refresh">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <v-card flat>
        <v-card-text>
          <v-data-table
              :headers="headers"
              :items="serviceList"
              :items-per-page="10"
              :loading="loading"
              loader-height="1"
          >
            <template v-slot:item.first_name="{ item }">
              {{ fulName(item.salut, item.first_name, item.mid_name, item.last_name) | capitalize }}
            </template>
            <template v-slot:item.name="{ item }">
              {{ item.name|capitalize }}
            </template>

            <template v-slot:item.accept="{ item }">
              <div v-if="item.accept === true">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click.stop="$set(myDetailDialog, item.id, true)">
                      <v-icon :color="item.accept ? 'primary' : 'error'">
                        {{ item.accept ? "mdi-tag-check-outline" : "mdi-tag-remove-outline" }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                  View Pricing Offered
                </span>
                </v-tooltip>
                <RequestMyDetails
                    v-if="myDetailDialog[item.id]"
                    :myDetailDialog="myDetailDialog[item.id]"
                    :myId="item.id"
                    :request="item.laboratory_requestid"
                    @closeMyDetail="closeMyDetail"/>
              </div>
              <div v-else>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn :to="{
                    name: 'lab.request.accept',
                    params:{
                      d: _encode(JSON.stringify(item)),
                      req: _encode(item.laboratory_requestid)
                    }
                  }"
                           icon v-on="on"
                    >
                      <v-icon :color="item.accept ? 'primary' : 'error'">
                        {{ item.accept ? "mdi-tag-check-outline" : "mdi-tag-remove-outline" }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                  Accept the offer by providing a response to the client
                  with it's pricing and other details
                </span>
                </v-tooltip>
              </div>
            </template>
            <RequestAccepts/>

            <template v-slot:item.price="{ item }">
              {{ currency(item.price) }}
            </template>

            <template v-slot:item.pay="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon :color="item.pay ? 'primary' : 'error'" v-on="on">
                    {{ item.pay ? "mdi-check" : "mdi-close" }}
                  </v-icon>
                </template>
                <span>
                  Payment for this service is {{ item.pay ? "made" : "not made" }}
                </span>
              </v-tooltip>
            </template>
            <RequestPays/>

            <template v-slot:item.appointment_date="{ item }">
              {{ item.appointment_date | myDating }}
            </template>

            <template v-slot:item.id="{ item }">
              <v-btn
                  color="primary"
                  text
                  x-small
                  @click.stop="$set(detailDialog, item.id, true)"
              >
                view
              </v-btn>
              <RequestDetail
                  v-if="detailDialog[item.id]"
                  :detailDialog="detailDialog[item.id]"
                  :myId="item.id"
                  :request="item.laboratory_requestid"
                  @closeView="closeView"/>

            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-snackbar
          v-model="snack.bar"
          :color="snack.color"
          :multi-line="snack.multiLine"
      >
        {{ snack.text }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import WinScroll from "../../mixins/windowscroll";
import Utils from "../../mixins/utils";
import RequestAccepts from "../../components/subsidiaries/labs/accepts.vue";
import RequestPays from "../../components/subsidiaries/labs/pays.vue";
import RequestDetail from "../../components/subsidiaries/labs/view.vue";
import RequestMyDetails from "../../components/subsidiaries/labs/details.vue";

export default {
  components: {RequestAccepts, RequestPays, RequestDetail, RequestMyDetails},
  mixins: [Utils, WinScroll("position")],
  data() {
    return {
      headDialog: {},
      editDialog: {},
      detailDialog: {},
      myDetailDialog: {},
      removeDialog: {},
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ... ",
      },
      loading: false,
      newDialog: false,
      crumbs: [
        {
          link: true,
          text: "Dashboard",
          to: {name: "lab.dash"},
        },
        {
          link: true,
          text: "Request",
          to: {name: "lab.request"},
        },
      ],
      requestList: [],
      headers: [
        {text: "client", value: "first_name"},
        {text: "subsidiary", value: "name"},
        {text: "accept", value: "accept", align: 'center'},
        {text: "price", value: "price",},
        {text: "pay", value: "pay"},
        {text: "appointment_date", value: "appointment_date"},
        {text: "action", value: "id"},
      ],
      serviceList: []
    };
  },
  computed: {
    flatApp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.list();
  },
  methods: {
    list() {
      this.loading = true;
      let self = this;
      Restful.medics.treatment.transact.providers.labs
          .requestList()
          .then((response) => {
            console.log(response.data);
            if (response.status === 200) {
              this.serviceList = response.data;
            }
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    refresh() {
      this.list();
    },
    fulName(sal, first, mid, last) {
      let mids = mid !== null ? mid : "";
      return sal + " " + first + " " + mids + " " + last;
    },
    menuLink() {
      this.$emit("sidebar");
    },
    closeNew(p) {
      this.newDialog = p.state;
    },
    submitNew(p) {
      console.log(p);
      this.snack.bar = true;
      let self = this;
      Restful.subscribers.subsidiary
          .create(p.data)
          .then((response) => {
            // console.log(response.data);
            this.snack.color = "success";
            this.snack.text = response.data;
            this.list();
          })
          .catch((error) => {
            console.log(error.response);
            self.snack.color = "error";
            self.snack.text = error.response.data;
          });
      this.newDialog = p.state;
    },
    closeHead(p) {
      this.$set(this.headDialog, p.id, p.state);
    },
    submitHead(p) {
      this.snack.bar = true;
      let self = this;
      Restful.subscribers.subsidiary
          .updateHead(p.id, p.data)
          .then((response) => {
            // console.log(response.data);
            this.snack.color = "success";
            this.snack.text = response.data;
            this.list();
          })
          .catch((error) => {
            console.log(error.response);
            self.snack.color = "error";
            self.snack.text = error.response.data;
          });
      this.$set(this.headDialog, p.id, p.state);
    },
    closeEdit(p) {
      this.$set(this.editDialog, p.id, p.state);
    },
    submitEdit(p) {
      this.snack.bar = true;
      console.log(p);
      let self = this;
      Restful.subscribers.subsidiary
          .update(p.id, p.data)
          .then((response) => {
            // console.log(response.data);
            this.snack.color = "success";
            this.snack.text = response.data;
            this.list();
          })
          .catch((error) => {
            console.log(error.response);
            self.snack.color = "error";
            self.snack.text = error.response.data;
          });
      this.$set(this.editDialog, p.id, p.state);
    },
    closeView(p) {
      this.$set(this.detailDialog, p.id, p.state);
    },
    closeRemove(p) {
      this.$set(this.removeDialog, p.id, p.state);
    },
    submitRemove(p) {
      this.snack.bar = true;
      console.log(p);
      let self = this;
      Restful.subscribers.subsidiary
          .remove(p.id)
          .then((response) => {
            // console.log(response.data);
            this.snack.color = "success";
            this.snack.text = response.data;
            this.list();
          })
          .catch((error) => {
            console.log(error.response);
            self.snack.color = "error";
            self.snack.text = error.response.data;
          });
      this.$set(this.removeDialog, p.id, p.state);
    },
    closeMyDetail(p) {
      this.$set(this.myDetailDialog, p.id, p.state);
    },
  },
};
</script>
