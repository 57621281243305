<template>
  <v-dialog v-model="paysDialog" max-width="700" persistent>
    <v-card loader-height="2">
      <v-card-title>update subsidiary</v-card-title>
      <v-card-text>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
                :disabled="$v.add.$invalid"
                color="blue"
                small
                text
                @click="submit"
                v-on="on"
            >
              submit
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "accepts",
  props: {
    paysDialog: {type: Boolean},
    myId: {type: Number},
  },
  data: () => ({}),
  created() {
  },
  methods: {
    closeDialog() {
      let payload = {id: this.myId, state: false};
      this.$emit("closePay", payload);
    },
    submit() {
      let payload = {id: this.myId, state: false};
      this.$emit("closePay", payload);
    }
  }
}
</script>